import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from 'react-google-recaptcha';  
import './RegistrationForm.css'; 

const RegistrationForm = () => {
  const [step, setStep] = useState(1); 
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    phone: '',
    pincode: '',
    vendorEmail: '',
    vendorProgram: '',
    gstNumber: '',
  });
  const [showPassword, setShowPassword] = useState(false); 
  const [captchaVerified, setCaptchaVerified] = useState(''); 
  const [errors, setErrors] = useState({});

  const validateStepOne = () => {
    let newErrors = {};
  
    if (!formData.name.trim()) newErrors.name = 'Name is required';

    if (!formData.username.trim()) newErrors.username = 'Username is required';
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) newErrors.email = 'Invalid email';
    
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;

    if (!passwordPattern.test(formData.password)) {
        newErrors.password = 'Password must be 8-15 characters, with at least one lowercase letter, one uppercase letter, one digit, and one special character';
    }    

    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const validateStepTwo = () => {
    let newErrors = {};
    
    if (!formData.companyName.trim()) newErrors.companyName = 'Company name is required';
    
    const phonePattern = /^[6-9]\d{9}$/;
    if (!phonePattern.test(formData.phone)) newErrors.phone = 'Invalid phone number';
    
    const pincodePattern = /^\d{6}$/;
    if (!pincodePattern.test(formData.pincode)) newErrors.pincode = 'Invalid pincode';
    
    const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!gstPattern.test(formData.gstNumber)) newErrors.gstNumber = 'Invalid GST number';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNext = () => {
    if (step === 1 && validateStepOne()) {
      setStep(2);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(value); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      alert('Please verify that you are not a robot.');
      return;
    }

    if (validateStepTwo()) {
      try {
        const response = await fetch('http://localhost:3000/verify-captcha', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: captchaVerified }),
        });

        const result = await response.json();

        if (result.success) {
          console.log(formData); 
          alert("Registration successful!");
        } else {
          alert("Captcha verification failed");
        }
      } catch (error) {
        console.error('Error during captcha verification:', error);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleSuccess = (response) => {
    console.log('Google login success', response);
  };

  const handleGoogleFailure = (error) => {
    console.log('Google login failed', error);
  };

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <div className="registration-form-container">
      <h2 class="registration-title">Startup Registration</h2>
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              <div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>

              <div>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Enter your username"
                  required
                />
                {errors.username && <span className="error">{errors.username}</span>}
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  required
                />
                <button type="button" onClick={togglePasswordVisibility}>
                  {showPassword ? "Hide" : "Show"}
                </button>
                {errors.password && <span className="error">{errors.password}</span>}
              </div>

              <div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm password"
                  required
                />
                {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
              </div>

              <button type="button" onClick={handleNext} className="next-button">
                Next
              </button>
            </>
          )}

          {step === 2 && (
            <>
              <div>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Enter your company name"
                  required
                />
                {errors.companyName && <span className="error">{errors.companyName}</span>}
              </div>

              <div>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>

              <div>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  placeholder="Enter your pincode"
                  required
                />
                {errors.pincode && <span className="error">{errors.pincode}</span>}
              </div>

              <div>
                <input
                  type="email"
                  name="vendorEmail"
                  value={formData.vendorEmail}
                  onChange={handleChange}
                  placeholder="Enter your vendor email"
                  required
                />
              </div>

              <div>
                <select
                  name="vendorProgram"
                  value={formData.vendorProgram}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Program</option>
                  <option value="Investors">Investors</option>
                  <option value="Business Connect">Business Connect</option>
                </select>
              </div>

              <div>
                <input
                  type="text"
                  name="gstNumber"
                  value={formData.gstNumber}
                  onChange={handleChange}
                  placeholder="Enter your GST number"
                  required
                />
                {errors.gstNumber && <span className="error">{errors.gstNumber}</span>}
              </div>

              <div>
                <ReCAPTCHA
                  sitekey="6LdpEj0qAAAAALUIXsBL1QasQoR2M4KQ49fhrAkA"
                  onChange={handleCaptchaChange}
                />
              </div>

              <button type="submit" className="register-button">
                Register
              </button>
            </>
          )}
        </form>

        <div className="social-login" style={socialLoginStyle}>
          <h3>Signup with your Google account</h3>
          <div style={socialButtonsContainer}>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleFailure}
            />
          </div>
          <p style={{ marginTop: '20px' }}>
            Already have an account? <a href="#" style={loginLinkStyle}>Login here</a>
          </p>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

const socialLoginStyle = {
  textAlign: 'center',
  marginTop: '20px'
};

const socialButtonsContainer = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginTop: '10px'
};

const loginLinkStyle = {
  color: '#ff7f00',
  textDecoration: 'none'
};

export default RegistrationForm;
